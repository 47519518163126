.app-main-header {
  /* background-color: #f15931 !important; */
  background-color: #4A863E !important;
  color: #fff !important;
}
ul.nav-menu li.menu.open .sub-menu li > a.active,
.jr-card-thumb {
  /* background-color: #f15931 !important; */
  background-color: #4A863E;
}

.app-sidebar .side-nav ul.nav-menu li button,
.app-sidebar .side-nav ul.nav-menu li a {
  padding: 10px 20px 10px 20px;
  color: #000;
}
.app-logo-content.d-flex.align-items-center.justify-content-center {
  /* background-image: url('assets/images/background.jpg'); */
  background-color: #0a0a0a;
}
.MuiListItemIcon-root {
  min-width: 30px !important;
}
.app-sidebar .side-nav {
  background: #fff !important;
  box-shadow: 5px 10px 18px #ced4da;
}
div.mini-drawer .app-main-header.custom-color {
  /* background: #f15931 !important; */
  background-color: #4A863E !important;
}

.app-sidebar ul.nav-menu li.menu.open > a,
.app-sidebar ul.nav-menu li a.active,
.app-sidebar .side-nav ul.nav-menu li a:hover,
.cbutton:hover {
  /* background: #f15931; */
  /* background: #4A863E; */
  background: #008000;
  color: #fff;
}
label.upload-btn {
  /* background: #f15931; */
  background: #4A863E;
}
ul.nav-menu li.menu .sub-menu li > a:hover,
ul.nav-menu li.menu .sub-menu li > a:focus,
ul.nav-menu li.menu.open .sub-menu li > a.active:before,
ul.nav-menu li.menu .sub-menu li > a:focus:before,
ul.nav-menu li.menu .sub-menu li > a:hover:before {
  background-color: #4A863E !important;
  /* background-color: #f15931 !important; */
  color: #000;
}
label.MuiInputLabel-shrink-200,
label.MuiInputLabel-focused-187 {
  color: #4A863E;
  /* color: #f15931; */
}
.page-heading i.zmdi-hc-fw {
  /* color: #f15931; */
  color: #4A863E;
}
.app-main-content .jr-btn,
button.CBmargin,
.app-login-form button {
  /* background-color: #f15931 !important; */
  background-color: #4A863E !important;
  color: white;
}
.app-main-content .jr-btn:hover,
.app-login-form button:hover {
  /* background: #f15931 !important; */
  background: #4A863E !important;
  text-decoration: none;
}
.app-container.fixed-drawer .app-main-header {
  background: #4A863E !important;
  /* background: #f15931 !important; */
}

div#form-dialog-title {
  background: #f15931;
}

/* Status labels*/

span.confirmed,
span.created {
  background: #ffcb00e0;
}

span.searching {
  background: #e0b91ffc;
}

span.started {
  background: #e0491f;
}

span.inrouteDrop {
  background: #b276e3;
}

span.inroutePick {
  background: #972ded;
}
span.label {
  background: gray;
}
span.completed,
span.In.Stock,
span.Online,
span.active,
span.approved,
span.Yes,
span.yes {
  background: green;
}

span.cancelled,
span.label.Out.Of.Stock,
span.cancel,
span.rejected,
span.blocked,
span.inactive,
span.No,
span.no {
  background: #fd2121;
}
span.label.Completed {
  background: forestgreen;
}


span.DestinationInroute,
span.PickupInroute,
span.inrouteDrop {
  background: #b276e3;
}

span.FindingTrips,
span.inroutePick,
span.FindingDrivers {
  background: #972ded;
}
span.Offline {
  background: #eb4d4d;
}

.uper-multi-row {
  background: #00000017;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
}

.pt .jr-btn,
button.CBmargin {
  /* background-color: #f15931 !important; */
  background-color: #4A863E !important;
  color: #fff;
}

.customizeModal .modal-content {
  max-width: 650px;
}

.common-btn {
  height: 40px;
  /* background: #f15931 !important; */
  background-color:#4A863E !important;
  color: #fff !important;
}

.Current-dipute,
.bill-details,
.cooman-class {
  margin-top: 40px;
}
.bill-details ul,
.footer-div ul,
.item-added-lsit ul,
.modal-login .modal-body {
  padding: 0;
}
.bill-details ul li:not(:last-child),
.deleivery-addresses ul li:not(:last-child),
.left-order-details h5,
ul.Dashboardsidebar-list li:not(:last-child) {
  border-bottom: 1px solid #ccc;
}
.bill-details ul li,
.deleivery-addresses ul li,
.dispute-items-main ul li {
  list-style-type: none;
  padding: 10px 0;
}

.bill-list,
.logo-here,
.poop-dispute,
.upcoming-itemsdiv {
  display: flex;
  justify-content: space-between;
}
.bill-list p {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
}

.new-logo{
  height:64px;
  width:166px;
}
button.total-pay {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-radius: 10px;
  width: 100%;
  border: none;
  background-color: #f16114;
  color: #fff;
}
.total-pay p {
  margin: 0;
  font-family: Roboto;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #fff;
}

.menulist .menuitem {
  height: auto;
  min-height: 40px;
  padding: 10px;
  background-color: #707070;
  left: 0 !important;
}
.vks .list .ItemWrpper {
  height: 50px;
}
.item {
  width: 100px;
  height: 50px;
  position: relative;
  z-index: 1;
  margin-bottom: 10px;
}
.list .item {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #f9593c;
  color: #fff;
  font-weight: 500;
}
.vks .list {
  display: flex;
  grid-gap: 30px;
  gap: 30px;
}

.menulist .menuitem {
  margin-bottom: 20px;
}
.menulist .menuitem {
  height: 40px;
  width: 150px;
  box-shadow: 1px 1px 5px #ccc;
  align-items: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: green;
  color: #fff;
  font-weight: 500;
  margin: 20px;
}
.menulist .menuitem {
  height: auto;
  min-height: 40px;
  padding: 10px;
  background-color: #707070;
  left: 0 !important;
}
.drag_drop h1 {
  padding: 10px;
  text-align: center;
  box-shadow: 1px 1px 5px #ccc;
  margin-top: 18px;
}
.menulist .menuitem.dragging,
.menulist .menuitem.draggingOver {
  top: 0 !important;
  position: relative !important;
}
.menulist .menuitem.draggingOver {
  top: unset !important;
  /* bottom : 0 !important */
}
.InnerCol .item.dragging {
  position: absolute !important;
  left: 0 !important;
  top: 0 !important;
}
.formCard .top h4 {
  font-size: 20px;
  font-weight: 500 !important;
  color: #3c3c3c;
}
.accountForm .MuiInput-formControl .MuiInput-input {
  border: 1px solid #ddd !important;
  margin: 0;
  border-radius: 5px;
  height: 51px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #666;
}
.accountForm .cstmSelect label,
.accountForm .CstmAddress .MuiFormControl-root label {
  transform: unset;
  font-size: 12px;
  background: #fff;
  display: inline-flex;
  padding: 0 6px;
  position: absolute;
  top: 10px;
  z-index: 99;
  left: 10px;
}
.accountForm .MuiFormControl-root.select-simple {
  margin: 0 !important;
}

.accountForm .MuiInput-formControl:after,
.accountForm .MuiInput-formControl:before {
  display: none;
}
.accountForm .CstmAddress .MuiFormControl-root {
  margin: 0;
}
section.MainAccountForm .accountForm .phoneInput .MuiFormControl-root input {
  border: 0 !important;
  background: transparent !important;
  height: 100% !important;
}
section.MainAccountForm .accountForm .phoneInput > div {
  margin-right: 0 !important;
}

.heading_timeslot li {
  list-style-type: none;
  width: 33.33%;
}

tr#monday-1 p,
tr#tuesday-2 p,
tr#wednesday-3 p,
tr#thursday-4 p,
tr#friday-5 p,
tr#saturday-6 p {
  display: none;
}

tr#sunday-0 p {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 12px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}


.MuiSvgIcon-colorError {
  color: #4A863E !important;
}

.MuiIconButton-root {
  color:#4A863E !important ;
}