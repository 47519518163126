html,
body {
  height: 100%;
}

.auth-container {
  height: 100%;
  background: white;
}
.left-sidebar {
  background: #f5f5f5;
  text-align: center;
}
form {
  width: 100%;
}
.app-login-content {
  padding: 15%;
  width: 100%;
}
.app-signup-content {
  padding: 5%;
  max-height: 550px;
  overflow-y: scroll;
  width: 100%;
}
.sidebars h3 {
  font-size: 30px;
  font-weight: 700;
}
h3.active,
p.active {
  color: #84c225;
}
h3.active::after {
  position: absolute;
  width: 0;
  height: 0;
  border-top: 16px solid transparent;
  border-bottom: 16px solid transparent;
  border-right: 22px solid #fff;
  content: "";
  right: -1px;
  top: 50%;
  transform: translateY(-50%);
}
.row.sidebars {
  height: 100%;
}
.left-sidebar .col-md-12 {
  padding: 35px 15px;
  cursor: pointer;
}
.logo-div {
  text-align: center;
}
.logo-div img {
  width: 45%;
}
.phoneInput {
  border: 1px solid #a5a5a5;
  border-radius: 6px;
}

.phoneInput label {
  font-size: 0;
}

.phoneInput .MuiInput-root {
  margin: 0;
}

.phoneInput .input-text {
  margin: 0;
  height: 45px !important;
}
.phoneInput .MuiInput-root {
  height: 100%;
}
.phoneInput input {
  height: 45px;
  background: transparent !important;
  border: 0 !important;
  padding: 10px;
  max-width: 100% !important;
  outline: 0 !important;
}
.phoneInput .MuiInput-root input.MuiInputBase-input.MuiInput-input {
  height: 25px !important;
}
.phoneInput .MuiInput-underline:after,
.phoneInput .MuiInput-underline:before {
  display: none;
}

.phoneInput > div:first-child {
  border-right: 1px solid #000;
  height: 100%;
}
